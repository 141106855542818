import React from "react";
import styled from 'styled-components'
import { auth } from "../../firebase";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
} from "firebase/auth";

import { useQuiz } from '../../context/QuizContext'
import { ScreenTypes } from '../../types'

import {
  CenterCardContainer,
  HighlightedText,
  LogoContainer,
  PageCenter,
} from '../../styles/Global'

import AppLogo from '../ui/AppLogo'
import Button from '../ui/Button'
import AboutUsScreen from '../AboutUsScreen'
import { ReactComponent as BulbIcon } from '../../assets/icons/bulb.svg'

const Heading = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
`

const DetailText = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
`

const DividerWithText = styled.div`
  border: 0;
  border-top: 1px solid #ccc;
  text-align: center;
  margin: 20px 0;
  position: relative;
  width: 80%;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    border-top: 1px solid #ccc;
    z-index: -1;
  }

  span {
    position: absolute;
    top: -10px;
    background: white;
    padding: 0 10px;
    font-size: 14px;
    color: #666;
    z-index: 1;
  }
`;

const Login: React.FC = () => {
  const { setCurrentScreen, currentScreen } = useQuiz()
  
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      console.log("Google User:", result.user);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error with Google Login:", error.message);
      }
    }
  };
  
  const handleFacebookLogin = async () => {
    const provider = new FacebookAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      console.log("Facebook User Info:", result.user);
    } catch (error) {
      console.error("Error during Facebook login:", error);
    }
  };
  
  const goToAboutUsScreen = () => {
    setCurrentScreen(ScreenTypes.AboutUsScreen)
  }
  
  if (currentScreen === ScreenTypes.AboutUsScreen) {
    return <AboutUsScreen />
  }

  return (
    <PageCenter light justifyCenter>
      <CenterCardContainer>
        <LogoContainer>
          <AppLogo />
        </LogoContainer>
        <Heading>
          <HighlightedText>Sheeple chào mừng bạn</HighlightedText>
        </Heading>
        <DetailText>Đăng nhập vào sheeple để tiếp tục bạn nhé!</DetailText>
        <DividerWithText />
        <Button text="Đăng nhập Gmail" onClick={handleGoogleLogin} big width="360px" />
        <br />
        <Button text="Đăng nhập Facebook" onClick={handleFacebookLogin} big width="360px" />
        <DividerWithText />
        <br />
        <Button icon={<BulbIcon />} iconPosition="left" text="Giới thiệu về chúng mình" onClick={goToAboutUsScreen} big width="400px" />
      </CenterCardContainer>
    </PageCenter>
  );
};

export default Login;
